import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useFormContext } from "react-hook-form";
import AppSelectList from "components/AppSelectList";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";
import { useAppSelector } from "store/configureStore";
import AppCheckbox from "components/AppCheckbox";
import { useEffect, useState } from "react";
import agent from "api/agent";
import { toast } from "react-toastify";
import { currencyFormat } from "util/util";

export default function OtherForm() {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [loading, setLoading] = useState(true);
  const [showMonthlyOrderQuestion, setShowMonthlyOrderQuestion] =
    useState(false);
  const [qualifyMonthlyOrderQuestion, setQualifyMonthlyOrderQuestion] =
    useState(false);

  //In case user was referred by an agent.

  const { user } = useAppSelector((state) => state.account);
  const { basket } = useAppSelector((state) => state.basket);

  function calculateReferredby() {
   
      if (user != null) {

          if (user.roles.includes("Winkel")) {
              setValue("referredBy", "");
              return "Nie van toepassing";
          }

      if (user.parentUserEmail !== null && user.parentUserEmail !== "") {
        setValue("referredBy", user.parentUserEmail);
        return user.parentUserEmail;
      }
    }

    setValue("referredBy", localStorage.getItem("agentkey") ?? "");
    return localStorage.getItem("agentkey") ?? "";
  }

  function CalculateMonthlyOrderRewardLevel() {
    var _amountTotalWithoutDelivery = basket.amountTotal - basket.amountDelivery;

    if (_amountTotalWithoutDelivery < 1500) {
      return (
        <Box>
          <AppCheckbox
            control={control}
            name="isMonthlyOrder"
            label="Ek kies hierdie as my maand bestelling. "
            disabled={true}
          />
          <Alert severity="error">
            Jou mandjie totaal, uitsluitend posgeld en koepons/geskenkbewys, is{" "}
            {currencyFormat(_amountTotalWithoutDelivery)}. Dit is nie genoeg om
            te kwalisifeer vir die maand bestelling bederf geskenk nie. As jy
            nog {currencyFormat(4000 - _amountTotalWithoutDelivery)} se items in
            jou bestelling byvoeg sal jy kan kwalifiseer vir die bederf
            geskenk.
          </Alert>
        </Box>
      );
    }

    //R1300
    if (
      _amountTotalWithoutDelivery >= 1500 &&
      _amountTotalWithoutDelivery < 4000
    ) {
      return (
        <Box>
          <AppCheckbox
            control={control}
            name="isMonthlyOrder"
            label="Ek kies hierdie as my maand bestelling, sonder 'n bederf geskenk. "
          />

          <Alert severity="info">
            Jou mandjie totaal, uitsluitend posgeld en koepons/geskenkbewys, is{" "}
            {currencyFormat(_amountTotalWithoutDelivery)}. As jy nog{" "}
            {currencyFormat(4000 - _amountTotalWithoutDelivery)} se items in jou
            bestelling byvoeg sal jy kan kwalifiseer vir die bederf
            geskenk.
          </Alert>
        </Box>
      );
    }

    
    //R4000
    if (_amountTotalWithoutDelivery > 4000) {

      return (
        <Box>
           <AppCheckbox
            control={control}
            name="isMonthlyOrder"
            label="Ek kies hierdie as my maand bestelling. (Kwalifiseer vir die bederf geskenk)"
          />
       
        <Alert severity="info">
          Jou mandjie totaal, uitsluitend posgeld en koepons/geskenkbewys, is{" "}
          {currencyFormat(_amountTotalWithoutDelivery)}. Maak seker jy kies
          hierdie as jou maand bestelling want jy kwalifiseer vir die bederf geskenk.
        </Alert>
        </Box>
      );
    }
  }

  useEffect(() => {
    if (user && user.roles?.includes("Agent")) {
      setShowMonthlyOrderQuestion(true);

      agent.MonthlyOrder.checkIfQualify()
        .then((response: boolean) => {
          setQualifyMonthlyOrderQuestion(response);
        })
        .catch((error) => toast.error("Kon nie maandboks opsie aflaai nie."))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography>Besig om besonderhede te laai...</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography fontWeight={700}>Betaling Opsie</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <AppSelectList
          name="paymentOption"
          label="Betaling Opsie"
          defaultValue="Direkte Bank Oordrag (EFT)"
          control={control}
          items={["Direkte Bank Oordrag (EFT)"]}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Alert severity="info">
          Ons bank besonderhede sal in die bestelling bevestiging epos en op die
          volgende skerm sodra bestelling geplaas is saam met jou bestelling
          nommer verskyn. (EFT: FNB, 250655, Tjek Rekening, 62814053972,
          Verwysing: Bestelling Nommer). <br />
          <br />
          Ons sal nie via epos of telefoon of sms versoek om die bank
          besonderhede te verander nie. Slegs eposse vanaf @kleinbederf.co.za of
          @kleinbederf.com is vanaf Klein gestuur, ons sal nooit ander eposse
          soos @hotmail of @gmail of so gebruik nie. Kontak ons by
          info@kleinbederf.co.za as jy enige epos kry met ander bank
          besonderhede.
        </Alert>
      </Grid>

     

          {!user?.roles.includes("Winkel") ?

              <>
                  <Grid item xs={12}>
                      <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                      <Typography fontWeight={700}>Verwysing:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                      <Typography>
                          Is daar 'n Klein Agent wat jou verwys het, indien wel, wat is die
                          Agent se e-posadres of unieke kode?{" "}
                      </Typography>
                      <TextField
                          label=""
                          variant="outlined"
                          name={"referredBy"}
                          fullWidth
                          {...register("referredBy")}
                          defaultValue={calculateReferredby()}
                          disabled={calculateReferredby() === "" ? false : true}
                          error={errors?.referredBy != null}
                          // @ts-ignore
                          helperText={errors?.referredBy?.message}
                      />
                  </Grid>
              </> : ""}

      {showMonthlyOrderQuestion && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography fontWeight={700}>Maand Bestelling:</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            {qualifyMonthlyOrderQuestion && (
              <CalculateMonthlyOrderRewardLevel />
            )}

            {!qualifyMonthlyOrderQuestion && (
              <Alert severity="info">
                Jy het klaar jou kwalifiseerende bestelling vir die maand
                geplaas.
              </Alert>
            )}
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography fontWeight={700}>Boodskap:</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography>
          Is daar enige boodskap of spesiale versoek wat jy wil byvoeg?
        </Typography>
        <TextField
          label=""
          variant="outlined"
          name={"remarks"}
          multiline
          rows={3}
          fullWidth
          {...register("remarks")}
          defaultValue={""}
          error={errors?.remarks != null}
          // @ts-ignore
          helperText={errors?.remarks?.message}
        />
      </Grid>
    </Grid>
  );
}

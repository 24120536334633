const pages = {
  tuisblad: [
    {
      title_af: 'Tuisblad',
      title_en: 'Home Page',
      href: '/',
    },
  ],
  oorklein: [
    {
      title_af: 'Kontak Ons',
      title_en: 'Contact Us',
      href: '/kontak',
    },
    {
      title_af: 'Ons Winkels',
      title_en: 'Our Shops',
      href: '/ons-winkels',
    },
    {
      title_af: 'Ons Handelshoeke',
      title_en: 'Our Trade Corners',
      href: '/ons-handelshoeke',
    },
    {
      title_af: 'Meer Oor Klein',
      title_en: 'More About Us',
      href: '/meeroorklein',
    },
    {
      title_af: 'Katalogus',
      title_en: 'Catalogue',
      href: '/katalogus',
    },
    {
      title_af: 'Bepalings en Voorwaardes',
      title_en: 'Terms and Conditions',
      href: '/bepalingsenvoorwaardes',
    },
    {
      title_af: 'Privaatheidsbeleid',
      title_en: 'Privacy Policy',
      href: '/privaatheidsbeleid',
    },
  ],
  maandboks: [
    {
      title_af: 'Dames Bokse',
      title_en: 'Ladies Box',
      href: '/maandboks-dames',
    },
    //{
    //  title_af: 'Kleintjie Bokse',
    //  title_en: 'Kids Box',
    //  href: '/maandboks-kleintjies',
    //},
    {
      title_af: 'Olie Bederf-boks',
      title_en: 'Oils Box',
      href: '/maandboks-olies',
    },
    //{
    //  title_af: 'Baba Maandbokse',
    //  title_en: 'Baby Box',
    //  href: '/maandboks-baba',
    //  isNew: true,
    //},
    //{
    //  title_af: 'Tween Maandbokse',
    //  title_en: 'Tween Box',
    //  href: '/maandboks-tween',
    //  isNew: true,
    //},
  ],
  agente: [
    {
      title_af: 'Agente',
      title_en: 'Agents',
      href: '/agente',
    },
    {
      title_af: 'Groothandel (Retailers)',
      title_en: 'Retailers',
      href: '/groothandelaar',
    },
    //{
    //  title_af: 'Handelshoek',
    //  title_en: 'Trade Corner',
    //  href: '/handelshoek',
    //},
  ],
  coloringbook: [
    {
      title_af: 'Klein se Inkleurboek',
      title_en: "Klein's Coloring Book",
      href: 'https://kleinbederf.com/uploads/meeroorklein/Inkleurboek_Klein.pdf',
      isNew: true,
    },
  ],

};

export default pages;
